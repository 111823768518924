import React, { Component } from 'react';

class TablePie extends Component {
    constructor(props) {
        super(props);
        this.state = {
            montoTotal: 0,
            count: 0,
            error: ''
        }
    }
    onClick(e, detalle, valor) {
        valor = parseInt(valor);
        if (valor <= 0 || valor > parseInt(detalle[0].CTAS_MNT_SALDO) || isNaN(valor)) {
            console.info("El monto debe ser mayor a 1 y menor o igual a " + detalle[0].CTAS_MNT_SALDO);
            this.setState({
                error: "El monto debe ser mayor a 1 y menor o igual a " + detalle[0].CTAS_MNT_SALDO
            })
            return false;
        }
        var objeto = {};
        detalle.forEach(todo => {
            objeto = [{
                'NUMERO_CONTRATO': todo.NUMERO_CONTRATO,
                'NUMERO_CUOTA': todo.NUMERO_CUOTA,
                'CONCEPTO_RECAUDACION': todo.CONCEPTO_RECAUDACION,
                'MONTO_CUOTA': parseInt(valor),
                'NUMERO_DOCUMENTO': todo.NUMERO_DOCUMENTO,
                'CUOTA_MOROSA': todo.CUOTA_MOROSA,
                'CODIGO_COMERCIO': todo.CODIGO_COMERCIO
            }];
        });

        this.props.onClick(false, objeto);
        this.props.onClick(true, objeto);
        this.setState({
            montoTotal: parseInt(valor),
            error: ''
        })
        this.props.calculaMontoTotal();
    }
    render() {
        const { data, text, id, title, type } = this.props;
        const { montoTotal, error } = this.state;
        const vigente = typeof data !== 'undefined' ? [data.vigente] : [];
        var show = true;
        return (
            <>
                {show && (
                    <>
                        <h2>{title}</h2>
                        <div className="table-responsive">
                            <table className="table" id={id}>
                                <thead>
                                    <tr className="head">
                                        <th scope="col">Fecha de Vencimiento</th>
                                        <th scope="col">Estado de pago</th>
                                        <th scope="col">Interes</th>
                                        <th scope="col">Recargo</th>
                                        <th scope="col">Ultimo pago</th>
                                        <th scope="col">Saldo pendiente</th>
                                        <th scope="col">Monto a pagar</th>
                                    </tr>
                                </thead>
                                <tbody className="body-tr">
                                    {vigente[0].monto > 0 ? (
                                        vigente.map((item) => (
                                            typeof data.vigente !== 'undefined' && item.monto > 0 && (
                                                [item.detalle].map((item2, index) => (
                                                    <tr key={index}>
                                                        <td align="center">{item2.FECHA_VENCIMIENTO}</td>
                                                        <td align="center">{item2.CTAS_IND_ESTADO_PAGO}</td>
                                                        <td align="center">${new Intl.NumberFormat("de-DE").format(item2.INTERESES)}</td>
                                                        <td align="center">${new Intl.NumberFormat("de-DE").format(item2.RECARGOS)}</td>
                                                        <td align="center">${new Intl.NumberFormat("de-DE").format(item2.ULT_PAGO)}</td>
                                                        <td align="center">${new Intl.NumberFormat("de-DE").format(parseInt(item2.CTAS_MNT_SALDO))}</td>
                                                        <td align="center">
                                                            <input type="number"
                                                                onBlur={
                                                                    (e) => { this.onClick(e, [item2], e.target.value) }
                                                                }
                                                                className={"form-control " + (error !== '' ? 'error' : '')}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))
                                            )
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="7" align="center">No existen cuotas pendientes</td>
                                        </tr>
                                    )
                                    }
                                    <tr>
                                        <td colSpan="7" align="left">
                                            <p>Usted como Cliente, puede pagar el pie durante la vigencia de esta propuesta. En tal caso, de no concurrir luego las partes a la celebración del contrato, La Empresa Sendero le reembolsará la totalidad del monto pagado por este concepto.</p>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan="7" align="right">{text}: ${new Intl.NumberFormat("de-DE").format(montoTotal)}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </>
                )
                }
            </>
        );
    }
}
export default TablePie;