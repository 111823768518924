import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ReactHtmlParser from "react-html-parser";
import Api from "../../service/Api";
import Flotante from '../Flotante';


import MegaMenuNew from './MegaMenuNew/index'
import { Link } from 'react-router-dom';

class DesktopRoutes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            nombrePlan: '',
            pagorapido: {},
            popup: {},
            detalle: {},
            misendero: {},
            registrar: {},
            alerta: {},
            alertadetalle: {},
        };
        this.toggle = this.toggle.bind(this);
    }
    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    componentWillMount() {
        this.getConf();
    }
    async getConf() {
        const dat = {};
        const data = await Api.getConf(dat);
        this.setState({
            pagorapido: data.result[0],
            popup: data.result[1],
            detalle: data.result[1].detalle[0],
            misendero: data.result[2],
            registrar: data.result[4],
            alerta: data.result[3],
            alertadetalle: data.result[3].detalle[0],
            modal: data.result[1].status === 1 ? true : false
        });
    }

    render() {
        const { popup, detalle, modal, alerta, alertadetalle } = this.state;
        return (
            <div style={{ position: 'fixed', top: 0, zIndex: 999, width: 100 + "%", backgroundColor: "#fff" }}>
                <div className="d-block d-md-none">
                    <div className="container">
                        <div className='row'>
                            <div className='col-6 text-center py-0 pl-0 pr-0'>
                                <Link to={'/mi-sendero'} className='box-top'>Mi Sendero</Link>
                            </div>
                            <div className='col-6 text-center py-0 pl-1 pr-0'>
                                <Link to={'/pago-rapido'} className='box-top'>Pago Rápido</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <MegaMenuNew alerta={typeof alerta !== 'undefined' && alerta.status === 1 && (
                    ReactHtmlParser(alertadetalle?.content)
                )} />
                {typeof popup !== 'undefined' && (
                    <Modal isOpen={modal} toggle={this.toggle}>
                        <ModalHeader toggle={this.toggle}></ModalHeader>
                        <ModalBody style={{ textAlign: 'center' }}>
                            {
                                typeof detalle !== 'undefined' && (
                                    <div>
                                        {ReactHtmlParser(detalle.content)}
                                    </div>
                                )
                            }
                        </ModalBody>
                    </Modal>
                )}
                <Flotante />
            </div>
        );
    }
}

export default DesktopRoutes;