import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Parques from './pages/Parques/';
import CanalDenuncia from './pages/Canal-Denuncia';
import CanalDenunciaEtico from './pages/Canal-Denuncia-Etico';
import Sepultura from './pages/Sepultura';
import Contacto from './pages/Contacto';
import MiSendero from './mi-sendero/routes';
import NotFound from './pages/404/NotFound';
import Posts from './components/Posts/Items';
import PostsSingle from './components/Posts/SinglePost';
import FuneralDia from './pages/Funerales-Dia';
import TipoMantenciones from './pages/Tipo-Mantencion';
import UbicacionSepultura from './pages/Ubicacion-Sepultura';
import PageAgradecimiento from './components/Gracias/PageAgradecimiento.jsx';
import PageAgradecimientoAgenteLibre from './components/Gracias/PageAgradecimientoAgenteLibre.jsx';
import ModelPage from './components/ModelPage/index';
import IngresoVentas from './pages/IngresoVentas';
import MantencionOfrenda from './pages/MantencionOfrenda';
import FormCotizar from './pages/FormCotizar';
import AccesoFirmante from './pages/Acceso-Firmante';
import CompraLinea from './pages/CompraLinea.jsx';
import Cremacion from './pages/Cremacion/Cremacion';
import DetailPago from './components/DetailPago/DetailPago';
import CompraLinea2 from './pages/CompraEnLinea/CompraLinea';
import Product from './pages/CompraEnLinea/Product';
import Checkout from './pages/CompraEnLinea/Checkout';
import End from './pages/CompraEnLinea/End';

import FormCotizarStep from './pages/FormCotizarStep';

const renderContent = () => {
  return (
    <>
      <div className="container-data">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/home/" component={Home} />
          <Route
            exact
            path="/ubicacion-de-sepulturas/"
            component={UbicacionSepultura}
          />
          <Route exact path="/funerales-del-dia/" component={FuneralDia} />
          <Route exact path="/sepultura/" component={Sepultura} />
          <Route exact path="/parque-crematorio/" component={Cremacion} />
          <Route exact path="/canal-de-denuncia/" component={CanalDenuncia} />
          <Route exact path="/canal-de-denuncia-etico/" component={CanalDenunciaEtico} />
          <Route exact path="/contacto/" component={Contacto} />
          <Route exact path="/cotizar/" component={FormCotizar} />
          <Route
            exact
            path="/cotizar-sepultura/"
            component={() => (
              <FormCotizarStep type="sepultura" title="Cotizar Sepultura" />
            )}
          />
          <Route
            exact
            path="/cotizar-cremacion/"
            component={() => (
              <FormCotizarStep type="cremacion" title="Cotizar Cremación" />
            )}
          />

          <Route
            exact
            path="/tipos-de-mantenciones/"
            component={TipoMantenciones}
          />
          <Route exact path="/mi-sendero/" component={MiSendero} />
          <Route exact path="/gracias/" component={PageAgradecimiento} />
          <Route exact path="/cotizar/gracias" component={PageAgradecimiento} />
          <Route exact path="/contacto/gracias" component={PageAgradecimiento} />
          <Route
            exact
            path="/gracias-agente-libre/"
            component={PageAgradecimientoAgenteLibre}
          />
          <Route exact path="/ingresoventas/" component={IngresoVentas} />
          <Route
            exact
            path="/mas-parque-para-todos/"
            component={MantencionOfrenda}
          />
          <Route exact path="/acceso-firmante/" component={AccesoFirmante} />
          <Route exact path="/compra-en-linea/" component={CompraLinea2} />
          <Route
            exact
            path="/compra-en-linea/payment/transbank/success/:buyorder/:status"
            component={End}
          />
          <Route
            exact
            path="/compra-en-linea/payment/transbank/error/"
            component={End}
          />
          {/* <Route exact path="/compra/" component={CompraLinea2} /> */}
          <Route exact path="/compra/product/:codigo/:id" component={Product} />
          <Route exact path="/compra/checkout" component={Checkout} />
          <Route
            exact
            path="/posts/sendero-contigo"
            component={() => <Posts type="2" title="Sendero Contigo" />}
          />
          <Route
            exact
            path="/posts/noticias"
            component={() => <Posts type="1" title="Noticias" />}
          />
          <Route
            exact
            path="/posts/sendero-contigo/:id/:title"
            component={PostsSingle}
          />
          <Route
            exact
            path="/posts/noticias/:id/:title"
            component={PostsSingle}
          />
          <Route path="/parques/" component={Parques} />
          <Route exact path="/:slug/" component={ModelPage} />
          <Route
            path="/detail/:origen/:buy_order/:token/:status/:code"
            component={DetailPago}
          />
          <Route component={NotFound} />
        </Switch>
      </div>
    </>
  );
};

export default renderContent;
