import React, { Component } from 'react';
import Api from '../../../../service/Api';

class AceptarCondiciones extends Component {
	constructor(props) {
		super(props)
		this.state = {
			disabledEnviar: true,
			verificaAceptar: true
		}
		this.check = this.check.bind(this);
		this.verifica = this.verifica.bind(this);
	}
	componentDidMount() {
		this.verifica();
	}
	check(e) {
		let data = e.target.checked ? true : false;
		this.setState({
			disabledEnviar: !data
		});
	}
	async verifica() {
		let dataApi = {};
		dataApi.rut = this.props.rut;
		dataApi.CONTRATO = this.props.contrato.CONTRATO;
		let data = await Api.verficaAceptar(dataApi);
		this.setState({
			verificaAceptar: data
		});
	}
	render() {
		const { index, contrato, aceptar } = this.props;
		const { disabledEnviar, verificaAceptar } = this.state;
		var mnsj = '';
		var view = false;
		switch (parseInt(verificaAceptar)) {
			case 1:
				mnsj = 'Estimado (a) cliente, para aceptar las condiciones de su contrato haga clic en el siguiente botón.';
				view = true;
				break;
			case 2:
				mnsj = 'Estimado (a) cliente, actualmente usted no puede aceptar las condiciones de su contrato pues se encuentra en proceso de aprobación. Nos comunicaremos con usted en un plazo máximo de 7 días hábiles para informarle sobre el estado de su contrato.';
				view = false;
				break;
			case 3:
				mnsj = 'Estimado (a) cliente, su contrato ya fue aceptado exitosamente.';
				view = false;
				break;
			default:
				break;
		}
		return (
			<>
				<div style={{ 'display': (view ? 'block' : 'none') }}>
					<p>Autorizo expresamente a la empresa y a las sociedades relacionadas a ella, según los términos establecidos en el artículo 100 de la Ley 18.045 de Mercado de Valores, ello de conformidad al artículo 4° de la Ley N°19.628 sobre Protección de la Vida Privada (“Ley de Protección de Datos”), a tratar sus datos personales, que proporciona al contratar nuestros servicios, por cualquiera de los canales de comunicación o venta, tanto presenciales como remotos, para las finalidades que la ley permite.</p>
					<p>En todo momento podrá revocar su autorización, y dispondrá siempre de los derechos de aclaración, rectificación, cancelación y oposición, y todos los demás derechos que confiere la Ley N° 19.628 sobre Protección de la Vida Privada. Para ejercer estos derechos sólo deberás llamar al  6000741380</p>
					<p>Declaro ser mayor de edad, tener plena capacidad para contratar, haber leído y estar de acuerdo con los documentos del contrato adjuntos, manifestando por este medio mi consentimiento con su suscripción en todas sus partes.</p>
					<br />
					<div className="row">
						<div className="col-12 col-md-12 text-right">
							<input type="checkbox" className="check_monto_mantencion_perpetua" id="check" onClick={(e) => { this.check(e) }} />
							<strong>¿Acepta las condiciones?</strong>
							<input type="button" value="Aceptar" className="btn btn-verde button btn-secondary" disabled={disabledEnviar} onClick={(e) => { aceptar(index, contrato) }} />
						</div>
					</div>
				</div>
				{!view && (
					<div className="alert alert-success">{mnsj}</div>
				)}
			</>
		)
	}
}
export default AceptarCondiciones;