import React, { Component } from 'react';
import { Row, Col, Button, Form, FormGroup, Input, Alert } from 'reactstrap';
import Layout from './../Layout/'
import Session from './../../service/Session';
import Api from './../../service/Api';
import {
    Modal,
    ModalBody
  } from 'reactstrap';

class MisDatos extends Component {

    constructor(props) {
        super(props);

        this.state = {
            params: {
                nombre: Session.getInfoUser().usuario,
                rut: Session.getInfoUser().rut,
                email: Session.getInfoUser().email === '' ? '' : Session.getInfoUser().email,
                telefono: parseInt(Session.getInfoUser().fono) === 0 ? '' : Session.getInfoUser().fono,
            },
            txtMsj: '',
            AlertClass: '',
            disabledButton: false,
            openmodal: false,
        }
        this.setForm = this.setForm.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    async setForm() {
        let response = await Api.actualizaMisDatos(this.state.params);
        if (response.result) {
            this.setState({
                txtMsj: "Correo enviado correctamente",
                AlertClass: "alert-success",
                disabledButton: false
            });
        } else {
            this.setState({
                txtMsj: "Ocurrió un problema, favor intentar nuevamente",
                AlertClass: "alert-danger",
            });
        }

    }
    handleInputChange(event) {
        this.setState({
            txtMsj: ""
        });
        const target = event.target;
        const value = target.value;
        const name = target.name;

        let current = this.state.params;
        current[name] = value;

        this.setState(current);

        if (this.state.params.nombre && this.state.params.rut && this.state.params.email && this.state.params.telefono) {
            this.setState({
                disabledButton: true
            });
        } else {
            this.setState({
                disabledButton: false
            });
        }
    }
    render() {
        return (
            <>
                <Modal isOpen={this.state.openmodal} size={"xl"} >
                    <ModalBody >
                        <Button color="" style={{position: 'absolute' , right: '10px', border: '1px solid gray'}} onClick={(e) => this.setState({openmodal: false})} >
                            Cerrar
                        </Button>
                        <div style={{fontSize: '28px' , fontWeight: 'bold', color: "#000"}}>NUEVA LEY N° 21.420</div>
                        <p>Estimado Cliente,</p>
                        <br />
                        <div className="row">
                           <div className="col-lg-7 col-md-6">
                                <p>A partir del 01 de enero de 2023 comenzará a regir la <span style={{fontWeight: 'bold', color: '#000'}}>nueva Ley N° 21.420, la cual indica que todos los pagos de servicios se encontrarán afectos a IVA</span></p>
                                <br />
                                <p>Debido a esta ley, Sendero está obligado legalmente a incorporar este impuesto a las Mantenciones y otros servicios, los cuales tendrán un <span style={{fontWeight: 'bold', color: '#000'}}>recargo del 19% por concepto de IVA</span>, según lo exigido por la autoridad, lo que será recaudado y entregado al Servicio de Impuestos Internos a beneficio fiscal.</p>
                                <br />
                                <p>Si necesita mas información, puede comunicarse al número telefónico <span style={{fontWeight: 'bold', color: '#00a5c8'}}>600 736 3376</span></p>
                                <br />
                                <p>Conozca la nueva Ley N° 21.420 <a href="https://www.sendero.cl/posts/noticias/188/a-partir-del-01-de-enero-2023,-nuestros-servicios-estar%C3%A1n-afectos-a-iva" target={"_blank"} ><span style={{fontWeight: 'bold', color: '#00a5c8'}}>aquí</span></a></p>
                            </div>
                            <div className="col-lg-5 col-md-6">
                                <img className="img img-responsive" src="https://sendero-api.gux.cl//storage/imagen_ivas.jpg" alt="Mensaje IVA" height="300"/>
                            </div> 
                        </div>
                    </ModalBody>
                </Modal>
                <Layout name="Mis Datos">
                    <Row>
                        <Col xs={12} md={6}>
                            <Form>
                                <FormGroup row>
                                    <Col>
                                        <p>Datos personales</p>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col xs={12} sm={12}>
                                        <Input type="text" name="nombre" id="nombre" placeholder="Nombre" onChange={this.handleInputChange} value={this.state.params.nombre || ''} disabled />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col xs={12} sm={12}>
                                        <Input type="text" name="rut" id="rut" placeholder="RUT" onChange={this.handleInputChange} value={this.state.params.rut || ''} disabled />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col xs={12} sm={12}>
                                        <Input type="email" name="email" id="email" placeholder="Email" onChange={this.handleInputChange} value={this.state.params.email || ''} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col xs={12} sm={12}>
                                        <Input type="tel" name="telefono" id="telefono" placeholder="Teléfono" onChange={this.handleInputChange} value={this.state.params.telefono || ''} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col sm={12} className="text-center">
                                        {this.state.txtMsj !== "" ? <Alert className={'alert ' + this.state.AlertClass}>{this.state.txtMsj}</Alert> : ""}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col sm={12} className="text-left">
                                        <Button className="btn btn-verde button" onClick={this.setForm} disabled={!this.state.disabledButton}>Actualizar datos</Button>
                                    </Col>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                    <p style={{textAlign: 'justify' , marginTop: '5px', color:'#fff', padding: '10px', backgroundColor: '#00A5C8'}}>Estimados Clientes, a partir del 01 de enero de 2023 comenzará a regir la nueva Ley Nº 21.420, la cual indica que todos los pagos de servicios se encontrarán afectos a IVA.
                        Debido a esta ley, Sendero está obligado legalmente a incorporar este impuesto a las Mantenciones y otros servicios, los cuales tendrán un recargo del 19% por concepto de IVA, según lo exigido por la autoridad, lo que será recaudado y entregado al Servicio de Impuestos Internos a beneficio fiscal.
                    </p>
                </Layout>
            </>
        );
    }
}
export default MisDatos;