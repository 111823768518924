import React, { Component } from 'react';

class AppText extends Component {
    render() {
        const { onChange, name, type, placeholder , disabled , maxLength , value} = this.props;
        return (
            <>
                {type !== 'textarea' ?
                    (
                        <input className="form-control" type={type} name={name} id={name} placeholder={placeholder} onChange={onChange} value={value} disabled ={disabled  ?? false} maxLength={maxLength ?? true}/>
                    ) : (
                        <textarea className="form-control" type={type} name={name} id={name} placeholder={placeholder} onChange={onChange} />
                    )
                }
            </>
        )
    }
}

export default AppText;