import React, { Component } from "react";

class MenuActions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { ObjectData } = this.props;
    return (
      <>
        <div className="col-12 offset-md-1 col-md-5 d-none d-md-block">
          {ObjectData && ObjectData.map((item, index) => (
            <a href={item.link} key={index}>
              <div className={"btn-top " + item.clases}>
                <i className={item.icon}></i> {item.name}
              </div>
            </a>
          ))}
        </div>
      </>
    );
  }
}

export default MenuActions;
