import React from "react";

import mas_informados from "../../../../assets/images/pseudobanner/banner_mas_informados_desktop.png";
import './pseudobanner.scss';
import { useEffect } from "react";

const PesudoBanner = () => {

  

  useEffect(() => {
    
    const handleClick = () => {
      // 👇️ refers to the image element
      const imageSections = document.querySelectorAll('.image-section-click');
      const imageSections0 = document.querySelectorAll('.image-section-click_0');
      const imageLink = document.querySelectorAll('.image-link-goto');
      const image = document.querySelector('.img-pseudo-banner');
      const imageWidth = image.clientWidth;
      imageSections.forEach((section, index) => {
        section.style.width = `${imageWidth*2/10}px`;
        section.style.height = `${image.clientHeight}px`; 
        try {
          imageLink[index].style.width = `${imageWidth*2/10}px`;  
        } catch (error) {
          
        }
    });
    imageSections0.forEach((section, index) => {
      section.style.height = `${image.clientHeight}px`; 
      section.style.width = `${imageWidth*4/10}px`;}); 
  }
    if (document.readyState === 'complete') {
      handleClick();
    } else {
      window.addEventListener('load', handleClick);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', handleClick);
    }

  }, []);

  return (
    <>
      <br />
      <div className="image-container-clicked">
        <div className="image-section-click_0"  style={{left: '0'}}>
        </div>
        <div className="image-section-click"  style={{left: '40%'}}>
            <a className="image-link-goto" href="https://masinformados.sendero.cl/productos-servicios-y-precios/" target="_blank" rel="noopener noreferrer">{}</a>
        </div>
        <div className="image-section-click"  style={{left: '55%'}}>
            <a className="image-link-goto" href="https://masinformados.sendero.cl/precios-sendero/" target="_blank" rel="noopener noreferrer">{}</a>
        </div>
        <div className="image-section-click" style={{left: '75%'}}>
            <a className="image-link-goto" href="https://masinformados.sendero.cl/contratos-y-reglamentos/" target="_blank" rel="noopener noreferrer">{}</a>
        </div>
        <img src={mas_informados} className="img-fluid img-pseudo-banner" alt="Imagen dividida"  style={{cursor: 'pointer'}} />
      </div>
    </>
  );
};

export default PesudoBanner;
