import React, { Component } from 'react';
import { ViewIvas ,BaseURLVoyager } from '../../config';

class TableCuota extends Component {
    constructor(props) {
        super(props);
        this.state = {
            montoTotal: 0,
            count: 0,
        }
        this.validadorEstado = this.validadorEstado.bind(this);
    }
    onClick(e, detalle, valor, tipo) {
        const { checkboxes } = this.props;
        var position = 0;
        var pos = 0;

        if (tipo === 'F') {
            pos = 0;
        } else if (tipo === 'M') {
            pos = 1;
        } else {
            pos = 2;
        };
        Object.keys(checkboxes[pos]).map((xs, index) => {
            {
                if ((detalle[0].NUMERO_CONTRATO + "-" + tipo + "-" + detalle[0].NUMERO_CUOTA) === xs) {
                    position = index;
                }
            }
        });

        var begin = typeof Object.keys(checkboxes[pos])[position + 1] !== 'undefined' ? Object.keys(checkboxes[pos])[position + 1] : null;
        var after = typeof Object.keys(checkboxes[pos])[position - 1] !== 'undefined' ? Object.keys(checkboxes[pos])[position - 1] : null;

        if (begin !== null) {
            checkboxes[pos][begin].estado = !e;
        }
        if (after !== null) {
            checkboxes[pos][after].estado = e;
        }
        this.props.onClick(e, detalle);
        var montoCalculo = 0;
        if (e) {
            montoCalculo = parseInt(valor) + parseInt(this.state.montoTotal);
        } else {
            montoCalculo = parseInt(this.state.montoTotal) - parseInt(valor);
        }
        this.setState({
            montoTotal: montoCalculo
        })
        this.props.calculaMontoTotal();
    }
    componentWillMount() {
        const { data, type } = this.props;
        const vigente = typeof data !== 'undefined' ? [data.vigente] : [];
        const moroso = typeof data !== 'undefined' ? [data.moroso] : [];
        //console.log(moroso);
        typeof moroso[0] !== 'undefined' && (
            parseInt(moroso[0].monto) > 0 && moroso.map((xs) => (
                xs.detalle.map((todo) => {
                    this.props.setCheckboxes(todo.NUMERO_CONTRATO, todo.NUMERO_CUOTA, type, this.validadorEstado(todo.CUOTA_MOROSA))
                })
            ))
        )
        typeof vigente[0] !== 'undefined' && (
            parseInt(vigente[0].monto) > 0 && vigente.map((xs) => (
                xs.detalle.map((todo) => {
                    //this.props.setCheckboxes(todo.NUMERO_CONTRATO, todo.NUMERO_CUOTA, type, this.validadorEstado(todo.CUOTA_MOROSA))
                    this.props.setCheckboxes(todo.NUMERO_CONTRATO, todo.NUMERO_CUOTA, type, this.validatorEstadoCuotaVigente(todo.NUMERO_CUOTA, xs.detalle , moroso));
                })
            ))
        )
    }

    validatorEstadoCuotaVigente(num_cuota, array_cuotas, array_morosas)  {
        let has_morosas = false;
        if(array_cuotas.length === 0){
            return true;
        }

        if( typeof array_morosas[0] !== 'undefined') {
            if(parseInt(array_morosas[0].monto) > 0) {
                if(array_morosas[0].detalle.length > 0){
                    has_morosas = true;
                }
            }
        }

        if(array_cuotas[0].NUMERO_CUOTA === num_cuota && has_morosas === false){
            return false;
        }  else {
            return true;
        }
    }

    validadorEstado(valor) {
        let retorno = true;
        if (parseInt(valor) === 1) {
            if (parseInt(this.state.count) === 0) {
                retorno = false;
                this.state.count = 1;
            } else {
                retorno = true;
            }
        }
        return retorno;
    }

    renderCabeceraTable() {
        const date = new Date();

        if(date.getFullYear() >= ViewIvas ) {
            return (<>
                    <thead>
                        <tr className="head">
                        <td align="center" width="20%">ITEM</td>
                        <td align="center" width="20%">MONTO NETO</td>
                        <td align="center" width="20%">IVA A BENEFICIO FISCAL (*)</td>
                        <td align="center" width="20%">MONTO A PAGAR</td>
                        <td align="center" width="20%">N° CUOTA</td>
                        <td align="center" width="20%">FECHA</td>
                        <td align="center" width="20%"></td>
                        </tr>
                    </thead>
                </>)
        }

        
        return (<>
                    <thead>
                        <tr className="head">
                        <td align="center" width="20%">ITEM</td>
                        <td align="center" width="20%">MONTO</td>
                        <td align="center" width="20%">N° CUOTA</td>
                        <td align="center" width="20%">FECHA</td>
                        <td align="center" width="20%"></td>
                        </tr>
                    </thead>
                </>)
       
    }

    renderDetalleTable() {
        const date = new Date();

        if(date.getFullYear() >= ViewIvas ) {
            return (<>
                    <thead>
                        <tr className="head">
                        <td align="center" width="20%">ITEM</td>
                        <td align="center" width="20%">MONTO NETO</td>
                        <td align="center" width="20%">IVA A BENEFICIO FISCAL (*)</td>
                        <td align="center" width="20%">MONTO A PAGAR</td>
                        <td align="center" width="20%">N° CUOTA</td>
                        <td align="center" width="20%">FECHA</td>
                        <td align="center" width="20%"></td>
                        </tr>
                    </thead>
                </>)
        }

        
        return (<>
                    <thead>
                        <tr className="head">
                        <td align="center" width="20%">ITEM</td>
                        <td align="center" width="20%">MONTO</td>
                        <td align="center" width="20%">N° CUOTA</td>
                        <td align="center" width="20%">FECHA</td>
                        <td align="center" width="20%"></td>
                        </tr>
                    </thead>
                </>)
       
    }

    render() {
        const { data, text, id, title, type, checkboxes } = this.props;
        var pos = 0;
        const renderCabeceraTable = this.renderCabeceraTable();
        const date = new Date();
        if (type === 'F') {
            pos = 0;
        } else if (type === 'M') {
            pos = 1;
        } else if (type === 'CM') {
            pos = 2;
        } else {
            pos = 3;
        }
        const { montoTotal } = this.state;
        const vigente = typeof data !== 'undefined' ? [data.vigente] : [];
        const moroso = typeof data !== 'undefined' ? [data.moroso] : [];

        var show = false;
        if (typeof vigente[0].permite_mpu === 'undefined') {
            show = true;
        } else if (vigente[0].permite_mpu === 'SI') {
            show = true;
        } else {
            show = false;
        }
        return (
            <>
                {show && typeof checkboxes[0] !== 'undefined' && (
                    <>
                        <h2>{title}</h2>
                        <div className="table-responsive">
                            <table className="table" id={id}>
                                {renderCabeceraTable}
                                <tbody className="body-tr">
                                    {typeof moroso[0] !== 'undefined' && moroso[0].monto > 0 ? (
                                        moroso.map((item) => (
                                            typeof data.moroso !== 'undefined' && item.monto > 0 && (
                                                // item.detalle.map((item2, index) => (
                                                item.detalle.map((item2,index) => {
                                                    const date = new Date();
                                                    if(date.getFullYear() >= ViewIvas) {
                                                        return (<>
                                                            <tr key={index}>
                                                                <td align="center" width="20%">Morosa</td>
                                                                <td align="center" width="20%">${new Intl.NumberFormat("de-DE").format(item2.MNT_NETO == null ? 0 : item2.MNT_NETO)}</td>
                                                                <td align="center" width="20%">${new Intl.NumberFormat("de-DE").format(item2.MONTO_IVA == null ? 0 : item2.MONTO_IVA)}</td>
                                                                <td align="center" width="20%">${new Intl.NumberFormat("de-DE").format(item2.MONTO_CUOTA == null ? 0 : item2.MONTO_CUOTA)}</td>
                                                                <td align="center" width="20%">{item2.NUMERO_CUOTA}</td>
                                                                <td align="center" width="20%">{item2.FECHA_VENCIMIENTO}</td>
                                                                <td align="center" width="20%">
                                                                    <input type="checkbox"
                                                                        onClick={
                                                                            (e) => { this.onClick(e.target.checked, [item2], item2.MONTO_CUOTA, type) }
                                                                        }
                                                                        disabled={checkboxes[pos][item2.NUMERO_CONTRATO + "-" + type + "-" + item2.NUMERO_CUOTA].estado}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        </>);
                                                    } else {
                                                    return (<>
                                                                <tr key={index}>
                                                                    <td align="center" width="20%">Morosa</td>
                                                                    <td align="center" width="20%">${new Intl.NumberFormat("de-DE").format(item2.MONTO_CUOTA)}</td>
                                                                    <td align="center" width="20%">{item2.NUMERO_CUOTA}</td>
                                                                    <td align="center" width="20%">{item2.FECHA_VENCIMIENTO}</td>
                                                                    <td align="center" width="20%">
                                                                        <input type="checkbox"
                                                                            onClick={
                                                                                (e) => { this.onClick(e.target.checked, [item2], item2.MONTO_CUOTA, type) }
                                                                            }
                                                                            disabled={checkboxes[pos][item2.NUMERO_CONTRATO + "-" + type + "-" + item2.NUMERO_CUOTA].estado}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            </>);
                                                    }
                                                })
                                                    
                                            )
                                        ))
                                    ) : (
                                            <tr>
                                                <td colSpan="5" align="center">No existen cuotas Morosas pendientes</td>
                                            </tr>
                                        )
                                    }
                                    {typeof vigente[0] !== 'undefined' && vigente[0].monto > 0 ? (
                                        vigente.map((item) => (
                                            typeof data.vigente !== 'undefined' && item.monto > 0 && (
                                                // item.detalle.map((item2, index) => (
                                                    
                                                // ))
                                                item.detalle.map((item2,index) => {
                                                    const date = new Date();

                                                    if(date.getFullYear() >= ViewIvas) {
                                                        return (<>
                                                            <tr key={index}>
                                                                <td align="center" width="20%">Vigente</td>
                                                                <td align="center" width="20%">${new Intl.NumberFormat("de-DE").format(item2.MNT_NETO == null ? 0 : item2.MNT_NETO)}</td>
                                                                <td align="center" width="20%">${new Intl.NumberFormat("de-DE").format(item2.MONTO_IVA == null ? 0 : item2.MONTO_IVA)}</td>
                                                                <td align="center" width="20%">${new Intl.NumberFormat("de-DE").format(item2.MONTO_CUOTA == null ? 0 : item2.MONTO_CUOTA)}</td>
                                                                <td align="center" width="20%">{item2.NUMERO_CUOTA}</td>
                                                                <td align="center" width="20%">{item2.FECHA_VENCIMIENTO}</td>
                                                                <td align="center" width="20%">
                                                                    {typeof data.moroso === 'undefined' || data.moroso.monto <= 0 ? ( // solo vigente

                                                                        <input type="checkbox"
                                                                            onClick={(e) => { this.onClick(e.target.checked, [item2], item2.MONTO_CUOTA, type) }}
                                                                            disabled={checkboxes[pos][item2.NUMERO_CONTRATO + "-" + type + "-" + item2.NUMERO_CUOTA].estado}
                                                                        />
                                                                    ) : ( //ambas
                                                                            <input type="checkbox"
                                                                                onClick={(e) => { this.onClick(e.target.checked, [item2], item2.MONTO_CUOTA, type) }}
                                                                                disabled={checkboxes[pos][item2.NUMERO_CONTRATO + "-" + type + "-" + item2.NUMERO_CUOTA].estado}
                                                                            />
                                                                        )
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </>);
                                                    } else {

                                                    return (<>
                                                                <tr key={index}>
                                                                    <td align="center" width="20%">Vigente</td>
                                                                    <td align="center" width="20%">${new Intl.NumberFormat("de-DE").format(item2.MONTO_CUOTA)}</td>
                                                                    <td align="center" width="20%">{item2.NUMERO_CUOTA}</td>
                                                                    <td align="center" width="20%">{item2.FECHA_VENCIMIENTO}</td>
                                                                    <td align="center" width="20%">
                                                                        {typeof data.moroso === 'undefined' || data.moroso.monto <= 0 ? ( // solo vigente

                                                                            <input type="checkbox"
                                                                                onClick={(e) => { this.onClick(e.target.checked, [item2], item2.MONTO_CUOTA, type) }}
                                                                                disabled={checkboxes[pos][item2.NUMERO_CONTRATO + "-" + type + "-" + item2.NUMERO_CUOTA].estado}
                                                                            />
                                                                        ) : ( //ambas
                                                                                <input type="checkbox"
                                                                                    onClick={(e) => { this.onClick(e.target.checked, [item2], item2.MONTO_CUOTA, type) }}
                                                                                    disabled={checkboxes[pos][item2.NUMERO_CONTRATO + "-" + type + "-" + item2.NUMERO_CUOTA].estado}
                                                                                />
                                                                            )
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            </>);

                                                    }
                                                })
                                            )
                                        ))
                                    ) : (
                                            <tr>
                                                <td colSpan="5" align="center">No existen cuotas Vigentes pendientes</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan="5" align="right">{text}: ${new Intl.NumberFormat("de-DE").format(montoTotal)}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </>
                )}
            </>
        );
    }
}
export default TableCuota;