import React, { Component } from 'react';
import { Col, Button, Form, FormGroup, Alert, Tooltip } from 'reactstrap';
import Api from '../../../../service/Api';
import cedula1 from "./../../../Assets/Images/SD__CI_ANTIGUA_VF.png";
import cedula2 from "./../../../Assets/Images/SD__CI_NUEVA_VF.png";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

class FormularioIngreso extends Component {
    constructor(props) {
        super(props);
        this.state = {
            AlertClass: 'alert-danger',
            txtMsj: '',
            tooltipOpen: false,
        }
        this.setForm = this.setForm.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleClickFoto = this.handleClickFoto.bind(this);
    }
    handleClickFoto(e, images) {
        this.setState({
            isOpen: true,
            images: images
        });
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });

        if (name === 'Rut') {
            let valor = event.target.value;
            valor = valor.replace(/[.-]/g, '').replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, '$1.$2.$3-$4')
            this.setState({
                Rut: valor,
            });
        }
        if (name === 'Nd') {
            let valor = event.target.value;
            valor = valor.replace('.', '', valor)
            this.setState({
                Nd: valor,
            });
        }

        if (this.state.Rut && this.state.Nd) {
            this.setState({
                disabledButton: true
            });
        } else {
            this.setState({
                disabledButton: false
            });
        }
    }
    async setForm() {
        if (this.state.Rut === '') {
            this.setState({
                txtMsj: 'Debe ingresar Rut',
                AlertClass: 'alert-danger',
            });
            return false;
        }
        if (this.state.Nd === '') {
            this.setState({
                txtMsj: 'Debe ingresar número de documento',
                AlertClass: 'alert-danger',
            });
            return false;
        }
        let response = await Api.verificaRut(this.state)
        let data = {};
        data.rut = this.state.Rut;
        data.nd = this.state.Nd;
        data.usuario = response.USUARIO;
        data.correo = response.CORREO;
        if (response.RESULTADO === "1") {
            this.props.siguientePaso(2, data);
        } else {
            this.setState({
                txtMsj: 'No se encuentra registrado su Rut como Cliente',
                disabledButton: false
            });
        }
    }
    toggle(e) {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        })
    }

    render() {
        const { images, isOpen, tooltipOpen } = this.state;
        return (
            <>
                <Form>
                    <FormGroup row>
                        <Col sm={12}>
                            <input type="text" name="Rut" id="Rut" onChange={this.handleInputChange} value={this.state.Rut || ''} placeholder="Rut" className="form-control" />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col sm={12}>
                            <input type="text" name="Nd" id="Nd" onChange={this.handleInputChange} value={this.state.Nd || ''} placeholder="N° Documento" className="form-control" />
                        </Col>
                    </FormGroup>
                    <div row>
                        <Col xs={12} sm={12}>
                            <div className="cedula">
                            <p className="pin" onClick={this.toggle}>¿Como ver el N de Documento? <i id="TooltipExample" className="fa fa-info-circle" style={{ 'color': '#94C01F', 'fontSize': 14 + 'px' }}></i></p>
                            <div className={"cedula__content " + (tooltipOpen ? 'open' : 'close')}>
                                <div className="row">
                                    <div className="col-6">
                                        <img src={cedula1} alt="" onClick={(e) => { this.handleClickFoto(e, cedula1) }} />
                                        <p className="title-doc">N de serie</p>
                                        <p className="subtitle">Cédula <span>Antigua</span></p>
                                    </div>
                                    <div className="col-6">
                                        <img src={cedula2} alt="" onClick={(e) => { this.handleClickFoto(e, cedula2) }} />
                                        <p className="title-doc">N de documento</p>
                                        <p className="subtitle">Cédula <span>Nueva</span></p>
                                    </div>
                                </div>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={images}
                                        imageCaption={''}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                    />
                                )}
                            </div>
                            </div>
                        </Col>
                    </div>

                    <FormGroup row>
                        <Col sm={12} className="text-center">
                            {this.state.txtMsj !== "" ? <Alert className={'alert ' + this.state.AlertClass}>{this.state.txtMsj}</Alert> : ""}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col sm={12}>
                            <Button className="btn btn-verde button btn-popup" onClick={this.setForm}>Siguiente</Button>
                        </Col>
                    </FormGroup>
                </Form>
            </>
        );
    }
}
export default FormularioIngreso;